<template>
  <div class="insurance-package">
    <MfoHouseholdPackageSelectInput
      id="mfo_package_select"
      @package-selected="packageSelected"
    />

    <!-- //ANCHOR - BasicRisc -->

    <MFODesktopPackageRowLayout class="first-submodus-row">
      <template #first>
        <SubmodusTitle
          :icon-size="16"
          :tooltip-content="texts.vtxt_kalkThirdPageBasicRisk_tooltipContent"
          :tooltip-title="texts.vtxt_kalkThirdPageBasicRisk_label"
        >
          {{ '20 alapkockázat' }}
        </SubmodusTitle>
      </template>
      <template #secondIcon>
        <PhIconManager
          class="submodus-check"
          height="1.25rem"
          width="1.25rem"
          ph-icon="ph-check"
        />
      </template>
      <template #thirdIcon>
        <PhIconManager
          class="submodus-check"
          height="1.25rem"
          width="1.25rem"
          ph-icon="ph-check"
        />
      </template>
      <template #fourthIcon>
        <PhIconManager
          class="submodus-check"
          height="1.25rem"
          width="1.25rem"
          ph-icon="ph-check"
        />
      </template>
      <template #fifthIcon>
        <PhIconManager
          class="submodus-check"
          height="1.25rem"
          width="1.25rem"
          ph-icon="ph-check"
        />
      </template>
    </MFODesktopPackageRowLayout>

    <!-- //ANCHOR - EstateBO -->

    <MfoEstateBaseInsurancePackageInput
      v-if="
        viewModel.InsuranceType.Value.value == 0 ||
          viewModel.InsuranceType.Value.value == 1
      "
      @calculate="callCalculate(true,2000)"
    />
    <!-- //ANCHOR - EstatePersonalitySumValueBO -->
    <MfoPersonalityPackageInput
      v-if="
        viewModel.InsuranceType.Value.value == 0 ||
          viewModel.InsuranceType.Value.value == 2
      "
      @calculate="callCalculate(true,2000)"
    />

    <!-- //ANCHOR - ValuablesSumBO -->
    <MfoValuablesPackageInput
      v-if="
        viewModel.InsuranceType.Value.value == 0 ||
          viewModel.InsuranceType.Value.value == 2
      "
      @calculate="callCalculate(true,2000)"
    />

    <!-- //ANCHOR - SteadingValue -->

    <MFODesktopPackageRowLayout
      v-for="(steading, steadingIndex) in viewModel.SteadingList"
      :key="'steading_' + steadingIndex"
    >
      <template #first>
        <SubmodusTitle
          :icon-size="16"
          :tooltip-content="texts.vtxt_kalk159_tooltipContent"
          :tooltip-title="steadingLabel(steadingIndex)"
        >
          {{ steadingLabel(steadingIndex) }}
        </SubmodusTitle>
      </template>
      <template #second>
        <TextInputPackage
          :id="'KALK_159_Extra_' + steadingIndex"
          v-model="steading.SteadingValue.Value"
          :maxlength="20"
          validation-rules="VAL_380|VAL_446"
          :init-input-value="steading.SteadingValue.Value"
          mask="### ### ### ### ###"
          :reverse-fill-mask="true"
          postfix="Ft"
          :is-disabled="isGTDisabled"
          :index="steadingIndex"
          :revalidate="revalidate[steadingIndex]"
          @update:model-value="callCalculate(true,2000),revalidateinput(steadingIndex)"
          @revalidated="revalidate[steadingIndex] = false"
        />
      </template>
      <template #third>
        <TextInputPackage
          :id="'KALK_159_Komfort_' + steadingIndex"
          v-model="steading.SteadingValue.Value"
          :maxlength="20"
          validation-rules="VAL_380|VAL_446"
          :init-input-value="steading.SteadingValue.Value"
          mask="### ### ### ### ###"
          :reverse-fill-mask="true"
          postfix="Ft"
          :is-disabled="isGTDisabled"
          :index="steadingIndex"
          :revalidate="revalidate[steadingIndex]"
          @update:model-value="callCalculate(true,2000),revalidateinput(steadingIndex)"
          @revalidated="revalidate[steadingIndex] = false"
        />
      </template>
      <template #fourth>
        <TextInputPackage
          :id="'KALK_159_StartPlus_' + steadingIndex"
          v-model="steading.SteadingValue.Value"
          :maxlength="20"
          validation-rules="VAL_380|VAL_446"
          :init-input-value="steading.SteadingValue.Value"
          mask="### ### ### ### ###"
          :reverse-fill-mask="true"
          postfix="Ft"
          :is-disabled="isGTDisabled"
          :index="steadingIndex"
          :revalidate="revalidate[steadingIndex]"
          @update:model-value="callCalculate(true,2000),revalidateinput(steadingIndex)"
          @revalidated="revalidate[steadingIndex] = false"
        />
      </template>
      <template #fifth>
        <TextInputPackage
          :id="'KALK_159_Start_' + steadingIndex"
          v-model="steading.SteadingValue.Value"
          :maxlength="20"
          validation-rules="VAL_380|VAL_446"
          :init-input-value="steading.SteadingValue.Value"
          mask="### ### ### ### ###"
          :reverse-fill-mask="true"
          :is-disabled="isMFODisabled"
          postfix="Ft"
          :index="steadingIndex"
          :revalidate="revalidate[steadingIndex]"
          @update:model-value="callCalculate(true,2000),revalidateinput(steadingIndex)"
          @revalidated="revalidate[steadingIndex] = false"
        />
      </template>
    </MFODesktopPackageRowLayout>

    <!-- //ANCHOR - SteadingPersonalEstateSumBO -->

    <MfoSteadingPersonalityPackageInput
      v-if="
        (viewModel.InsuranceType.Value.value == 0 &&
          viewModel.SteadingNumber.Value > 0) ||
          viewModel.InsuranceType.Value.value == 2
      "
      @calculate="callCalculate(true,2000)"
    />

    <!-- //ANCHOR - LiabilityInsurance -->
    <MfoLiabilityInsurancePackageInput @calculate="callCalculate(true,2000)" />

    <!-- //ANCHOR - Additionals -->
    <template
      v-for="(item, index) in visibleAdditionalCategories"
      :key="index"
    >
      <MFODesktopPackageRowLayout
        v-if="
          !item.IsVirtualPackage &&
            item.Id != 'IngatlanKiegeszitoKockazatokEpitesSzereles'
        "
        :is-extra-text-only="
          (((showSubmodusInput(item, viewModel.Packages.Extra) &&
            getSubmodusValues(item, viewModel.Packages.Extra).length == 1) ||
            getSubmodusReadOnly(item, viewModel.Packages.Extra)) &&
            !isSubmodusGift(item, viewModel.Packages.Extra)) ||
            isSubmodusGift(item, viewModel.Packages.Extra)
        "
        :is-komfort-text-only="
          (((showSubmodusInput(item, viewModel.Packages.Komfort) &&
            getSubmodusValues(item, viewModel.Packages.Komfort).length == 1) ||
            getSubmodusReadOnly(item, viewModel.Packages.Komfort)) &&
            !isSubmodusGift(item, viewModel.Packages.Komfort)) ||
            isSubmodusGift(item, viewModel.Packages.Komfort)
        "
        :is-start-plus-text-only="
          (((showSubmodusInput(item, viewModel.Packages.StartPlus) &&
            getSubmodusValues(item, viewModel.Packages.StartPlus).length ==
            1) ||
            getSubmodusReadOnly(item, viewModel.Packages.StartPlus)) &&
            !isSubmodusGift(item, viewModel.Packages.StartPlus)) ||
            isSubmodusGift(item, viewModel.Packages.StartPlus)
        "
        :is-start-text-only="
          (((showSubmodusInput(item, viewModel.Packages.Start) &&
            getSubmodusValues(item, viewModel.Packages.Start).length == 1) ||
            getSubmodusReadOnly(item, viewModel.Packages.Start)) &&
            !isSubmodusGift(item, viewModel.Packages.Start)) ||
            isSubmodusGift(item, viewModel.Packages.Start)
        "
        :is-gift="
          isSubmodusGift(item, viewModel.Packages.Extra) ||
            isSubmodusGift(item, viewModel.Packages.Komfort) ||
            isSubmodusGift(item, viewModel.Packages.StartPlus) ||
            isSubmodusGift(item, viewModel.Packages.Start)
        "
      >
        <template #first>
          <div>
            <SubmodusTitle
              v-sanitize="item.Name" 
              :icon-size="16"
              :tooltip-content="item.InformationContent"
              :tooltip-title="item.Name"
            />
            <div class="submodus-modification-button-holder">
              <div
                v-if="viewModel.MNBMode == undefined"
                class="submodus-modification-button"
                :class="
                  (showSubmodusInput(item, viewModel.Packages.Extra) &&
                    !isSubmodusGift(item, viewModel.Packages.Extra)) ||
                    (showSubmodusInput(item, viewModel.Packages.Komfort) &&
                      !isSubmodusGift(item, viewModel.Packages.Komfort)) ||
                    (showSubmodusInput(item, viewModel.Packages.StartPlus) &&
                      !isSubmodusGift(item, viewModel.Packages.StartPlus)) ||
                    (showSubmodusInput(item, viewModel.Packages.Start) &&
                      !isSubmodusGift(item, viewModel.Packages.Start))
                    ? 'active'
                    : ''
                "
                @click="removeSubmodus(item)"
              >
                Törlés
              </div>
              <div
                v-else
                class="submodus-modification-button"
                :class="
                  showSubmodusInput(item, viewModel.Packages.Start) &&
                    !isSubmodusGift(item, viewModel.Packages.Start)
                    ? 'active'
                    : ''
                "
                @click="removeSubmodus(item)"
              >
                Törlés
              </div>
              <div
                v-if="viewModel.MNBMode == undefined"
                class="submodus-modification-button"
                :class="
                  (!showSubmodusInput(item, viewModel.Packages.Extra) &&
                    !isSubmodusGift(item, viewModel.Packages.Extra)) ||
                    (!showSubmodusInput(item, viewModel.Packages.Komfort) &&
                      !isSubmodusGift(item, viewModel.Packages.Komfort)) ||
                    (!showSubmodusInput(item, viewModel.Packages.StartPlus) &&
                      !isSubmodusGift(item, viewModel.Packages.StartPlus)) ||
                    (!showSubmodusInput(item, viewModel.Packages.Start) &&
                      !isSubmodusGift(item, viewModel.Packages.Start))
                    ? 'active'
                    : ''
                "
                @click="addSubmodus(item)"
              >
                HOZZÁADÁS
              </div>
              <div
                v-else
                class="submodus-modification-button"
                :class="
                  !showSubmodusInput(item, viewModel.Packages.Start) &&
                    !isSubmodusGift(item, viewModel.Packages.Start)
                    ? 'active'
                    : ''
                "
                @click="addSubmodus(item)"
              >
                HOZZÁADÁS
              </div>
            </div>
          </div>
        </template>
        <template
          v-if="
            showSubmodusInput(item, viewModel.Packages.Extra) &&
              viewModel.MNBMode == undefined
          "
          #second
        >
          <SelectInput
            v-if="
              showSubmodusInput(item, viewModel.Packages.Extra) &&
                getSubmodusValues(item, viewModel.Packages.Extra).length > 1 &&
                !getSubmodusReadOnly(item, viewModel.Packages.Extra) &&
                !isSubmodusGift(item, viewModel.Packages.Extra)
            "
            :id="'extra_' + item.Id"
            :key="getSubmodusValue(item, viewModel.Packages.Extra).Value"
            v-model="getSubmodusValue(item, viewModel.Packages.Extra).Value"
            :is-disabled="isGTDisabled"
            :texts="texts"
            :options="getSubmodusValues(item, viewModel.Packages.Extra)"
            :init-option-value="
              getSubmodusValue(item, viewModel.Packages.Extra).Value.value
            "
            :format-number="true"
            :suffix="getSubmodusPostfix(item)"
            @update:model-value="
              setSubmodusValue(
                item,
                getSubmodusValue(item, viewModel.Packages.Extra).Value,
                getSubmodusValues(item, viewModel.Packages.Extra)
              )
            "
          />
          <div
            v-if="
              ((showSubmodusInput(item, viewModel.Packages.Extra) &&
                getSubmodusValues(item, viewModel.Packages.Extra).length ==
                1) ||
                getSubmodusReadOnly(item, viewModel.Packages.Extra)) &&
                !isSubmodusGift(item, viewModel.Packages.Extra)
            "
          >
            {{
              getFormattedNumber(
                getSubmodusValues(item, viewModel.Packages.Extra)[0].label
              ) + ' Ft'
            }}
          </div>
          <div v-if="isSubmodusGift(item, viewModel.Packages.Extra)">
            {{
              getSubmodusGiftValue(getSubmodusValues(item, viewModel.Packages.Extra)) +
                ' ' +
                getSubmodusPostfix(item)
            }}
          </div>
        </template>
        <template
          v-if="
            !showSubmodusInput(item, viewModel.Packages.Extra) &&
              !isSubmodusGift(item, viewModel.Packages.Extra) &&
              viewModel.MNBMode == undefined
          "
          #secondIcon
        >
          <PhIconManager
            class="submodus-cross"
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-x"
          />
        </template>
        <template
          v-if="
            showSubmodusInput(item, viewModel.Packages.Komfort) ||
              (isSubmodusGift(item, viewModel.Packages.Komfort) &&
                viewModel.MNBMode == undefined)
          "
          #third
        >
          <SelectInput
            v-if="
              showSubmodusInput(item, viewModel.Packages.Komfort) &&
                getSubmodusValues(item, viewModel.Packages.Komfort).length > 1 &&
                !getSubmodusReadOnly(item, viewModel.Packages.Komfort) &&
                !isSubmodusGift(item, viewModel.Packages.Komfort)
            "
            :id="'komfort_' + item.Id"
            :key="getSubmodusValue(item, viewModel.Packages.Komfort).Value"
            v-model="getSubmodusValue(item, viewModel.Packages.Komfort).Value"
            :is-disabled="isGTDisabled"
            :texts="texts"
            :options="getSubmodusValues(item, viewModel.Packages.Komfort)"
            :init-option-value="
              getSubmodusValue(item, viewModel.Packages.Komfort).Value.value
            "
            :format-number="true"
            :suffix="getSubmodusPostfix(item)"
            @update:model-value="
              setSubmodusValue(
                item,
                getSubmodusValue(item, viewModel.Packages.Komfort).Value,
                getSubmodusValues(item, viewModel.Packages.Komfort)
              )
            "
          />
          <div
            v-if="
              ((showSubmodusInput(item, viewModel.Packages.Komfort) &&
                getSubmodusValues(item, viewModel.Packages.Komfort).length ==
                1) ||
                getSubmodusReadOnly(item, viewModel.Packages.Komfort)) &&
                !isSubmodusGift(item, viewModel.Packages.Komfort)
            "
          >
            {{
              getFormattedNumber(
                getSubmodusValues(item, viewModel.Packages.Komfort)[0].label
              ) +
                ' ' +
                getSubmodusPostfix(item)
            }}
          </div>
          <div v-if="isSubmodusGift(item, viewModel.Packages.Komfort)">
            {{
              getSubmodusGiftValue(getSubmodusValues(item, viewModel.Packages.Komfort)) +
                ' ' +
                getSubmodusPostfix(item)
            }}
          </div>
        </template>
        <template
          v-if="
            !showSubmodusInput(item, viewModel.Packages.Komfort) &&
              !isSubmodusGift(item, viewModel.Packages.Komfort) &&
              viewModel.MNBMode == undefined
          "
          #thirdIcon
        >
          <PhIconManager
            class="submodus-cross"
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-x"
          />
        </template>
        <template
          v-if="
            showSubmodusInput(item, viewModel.Packages.StartPlus) ||
              (isSubmodusGift(item, viewModel.Packages.StartPlus) &&
                viewModel.MNBMode == undefined)
          "
          #fourth
        >
          <SelectInput
            v-if="
              showSubmodusInput(item, viewModel.Packages.StartPlus) &&
                getSubmodusValues(item, viewModel.Packages.StartPlus).length >
                1 &&
                !getSubmodusReadOnly(item, viewModel.Packages.StartPlus) &&
                !isSubmodusGift(item, viewModel.Packages.StartPlus)
            "
            :id="'startPlus_' + item.Id"
            :key="getSubmodusValue(item, viewModel.Packages.StartPlus).Value"
            v-model="getSubmodusValue(item, viewModel.Packages.StartPlus).Value"
            :is-disabled="isGTDisabled"
            :texts="texts"
            :options="getSubmodusValues(item, viewModel.Packages.StartPlus)"
            :init-option-value="
              getSubmodusValue(item, viewModel.Packages.StartPlus).Value.value
            "
            :format-number="true"
            :suffix="getSubmodusPostfix(item)"
            @update:model-value="
              setSubmodusValue(
                item,
                getSubmodusValue(item, viewModel.Packages.StartPlus).Value,
                getSubmodusValues(item, viewModel.Packages.StartPlus)
              )
            "
          />
          <div
            v-if="
              ((showSubmodusInput(item, viewModel.Packages.StartPlus) &&
                getSubmodusValues(item, viewModel.Packages.StartPlus).length ==
                1) ||
                getSubmodusReadOnly(item, viewModel.Packages.StartPlus)) &&
                !isSubmodusGift(item, viewModel.Packages.StartPlus)
            "
          >
            {{
              getFormattedNumber(
                getSubmodusValues(item, viewModel.Packages.StartPlus)[0].label
              ) +
                ' ' +
                getSubmodusPostfix(item)
            }}
          </div>
          <div v-if="isSubmodusGift(item, viewModel.Packages.StartPlus)">
            {{
              getSubmodusGiftValue(getSubmodusValues(item, viewModel.Packages.StartPlus)) +
                ' ' +
                getSubmodusPostfix(item)
            }}
          </div>
        </template>
        <template
          v-if="
            !showSubmodusInput(item, viewModel.Packages.StartPlus) &&
              !isSubmodusGift(item, viewModel.Packages.StartPlus) &&
              viewModel.MNBMode == undefined
          "
          #fourthIcon
        >
          <PhIconManager
            class="submodus-cross"
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-x"
          />
        </template>
        <template
          v-if="
            showSubmodusInput(item, viewModel.Packages.Start) ||
              isSubmodusGift(item, viewModel.Packages.Start)
          "
          #fifth
        >
          <SelectInput
            v-if="
              showSubmodusInput(item, viewModel.Packages.Start) &&
                getSubmodusValues(item, viewModel.Packages.Start).length > 1 &&
                !getSubmodusReadOnly(item, viewModel.Packages.Start) &&
                !isSubmodusGift(item, viewModel.Packages.Start)
            "
            :id="'start_' + item.Id"
            :key="getSubmodusValue(item, viewModel.Packages.Start).Value"
            v-model="getSubmodusValue(item, viewModel.Packages.Start).Value"
            :is-disabled="isMFODisabled"
            :texts="texts"
            :options="getSubmodusValues(item, viewModel.Packages.Start)"
            :init-option-value="
              getSubmodusValue(item, viewModel.Packages.Start).Value.value
            "
            :format-number="true"
            :suffix="getSubmodusPostfix(item)"
            @update:model-value="
              setSubmodusValue(
                item,
                getSubmodusValue(item, viewModel.Packages.Start).Value,
                getSubmodusValues(item, viewModel.Packages.Start)
              )
            "
          />
          <div
            v-if="
              ((showSubmodusInput(item, viewModel.Packages.Start) &&
                getSubmodusValues(item, viewModel.Packages.Start).length ==
                1) ||
                getSubmodusReadOnly(item, viewModel.Packages.Start)) &&
                !isSubmodusGift(item, viewModel.Packages.Start)
            "
          >
            {{
              getFormattedNumber(
                getSubmodusValues(item, viewModel.Packages.Start)[0].label
              ) +
                ' ' +
                getSubmodusPostfix(item)
            }}
          </div>
          <div v-if="isSubmodusGift(item, viewModel.Packages.Start)">
            {{
              getSubmodusGiftValue(getSubmodusValues(item, viewModel.Packages.Start)) +
                ' ' +
                getSubmodusPostfix(item)
            }}
          </div>
        </template>
        <template
          v-if="
            !showSubmodusInput(item, viewModel.Packages.Start) &&
              !isSubmodusGift(item, viewModel.Packages.Start)
          "
          #fifthIcon
        >
          <PhIconManager
            class="submodus-cross"
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-x"
          />
        </template>
      </MFODesktopPackageRowLayout>

      <!-- //ANCHOR - VirtualAdditionals -->

      <q-expansion-item
        v-if="item.IsVirtualPackage"
        v-model="accordion[index]"
        header-class="package-accordion-header"
        class="package-accordion"
        expand-icon-class="package-accordion-icon"
        expand-icon-toggle
      >
        <template #header>
          <MFODesktopPackageRowLayout>
            <template #first>
              <div class="package-accordion-name">
                <div class="left-holder">
                  <SubmodusTitle
                    :icon-size="16"
                    :tooltip-content="item.InformationContent"
                    :tooltip-title="item.Name"
                  >
                    {{ item.Name }}
                  </SubmodusTitle>
                  <div class="submodus-modification-button-holder">
                    <div
                      class="submodus-modification-button"
                      :class="
                        isVirtualPackageDeleteActive(item) &&
                          !isVirtualPackageOnlyMFOGift(item) &&
                          !isVirtualPackageOnlyGTGift(item)
                          ? 'active'
                          : ''
                      "
                      @click="removeVirtualPackage(item)"
                    >
                      Törlés
                    </div>

                    <div
                      class="submodus-modification-button"
                      :class="
                        isVirtualPackageAddActive(item) &&
                          !isVirtualPackageOnlyMFOGift(item) &&
                          !isVirtualPackageOnlyGTGift(item)
                          ? 'active'
                          : ''
                      "
                      @click="addVirtualPackage(item, viewModel.Packages.Extra)"
                    >
                      HOZZÁADÁS
                    </div>
                  </div>
                </div>
                <div class="right-holder">
                  <q-btn
                    :ripple="false"
                    flat
                    @click.stop="accordion[index] = !accordion[index]"
                  >
                    <PhIconManager
                      v-if="!accordion[index]"
                      height="1rem"
                      width="1rem"
                      ph-icon="ph-caret-down"
                    />
                    <PhIconManager
                      v-if="accordion[index]"
                      height="1rem"
                      width="1rem"
                      ph-icon="ph-caret-up"
                    />
                  </q-btn>
                </div>
              </div>
            </template>
            <template #secondIcon>
              <PhIconManager
                v-if="
                  !showSubmodusInput(item, viewModel.Packages.Extra) &&
                    !hasVirtualGift(item, viewModel.Packages.Extra)
                "
                class="submodus-cross"
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-x"
              />
              <PhIconManager
                v-else
                class="submodus-check"
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-check"
              />
            </template>
            <template #thirdIcon>
              <PhIconManager
                v-if="
                  !showSubmodusInput(item, viewModel.Packages.Komfort) &&
                    !hasVirtualGift(item, viewModel.Packages.Komfort)
                "
                class="submodus-cross"
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-x"
              />
              <PhIconManager
                v-else
                class="submodus-check"
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-check"
              />
            </template>
            <template #fourthIcon>
              <PhIconManager
                v-if="
                  !showSubmodusInput(item, viewModel.Packages.StartPlus) &&
                    !hasVirtualGift(item, viewModel.Packages.StartPlus)
                "
                class="submodus-cross"
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-x"
              />
              <PhIconManager
                v-else
                class="submodus-check"
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-check"
              />
            </template>
            <template #fifthIcon>
              <PhIconManager
                v-if="
                  !showSubmodusInput(item, viewModel.Packages.Start) &&
                    !hasVirtualGift(item, viewModel.Packages.Start)
                "
                class="submodus-cross"
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-x"
              />
              <PhIconManager
                v-else
                class="submodus-check"
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-check"
              />
            </template>
          </MFODesktopPackageRowLayout>
        </template>
        <MFODesktopPackageRowLayout
          v-for="(virtualItem, virtualIndex) in item.Items"
          :key="virtualIndex"
          :is-extra-text-only="
            (((showSubmodusInput(virtualItem, viewModel.Packages.Extra, item) &&
              getSubmodusValues(virtualItem, viewModel.Packages.Extra, item)
                .length == 1) ||
              getSubmodusReadOnly(
                virtualItem,
                viewModel.Packages.Extra,
                item
              )) &&
              !isSubmodusGift(virtualItem, viewModel.Packages.Extra, item)) ||
              isSubmodusGift(virtualItem, viewModel.Packages.Extra, item)
          "
          :is-komfort-text-only="
            (((showSubmodusInput(
              virtualItem,
              viewModel.Packages.Komfort,
              item
            ) &&
              getSubmodusValues(virtualItem, viewModel.Packages.Komfort, item)
                .length == 1) ||
              getSubmodusReadOnly(
                virtualItem,
                viewModel.Packages.Komfort,
                item
              )) &&
              !isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item)) ||
              isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item)
          "
          :is-start-plus-text-only="
            (((showSubmodusInput(
              virtualItem,
              viewModel.Packages.StartPlus,
              item
            ) &&
              getSubmodusValues(virtualItem, viewModel.Packages.StartPlus, item)
                .length == 1) ||
              getSubmodusReadOnly(
                virtualItem,
                viewModel.Packages.StartPlus,
                item
              )) &&
              !isSubmodusGift(
                virtualItem,
                viewModel.Packages.StartPlus,
                item
              )) ||
              isSubmodusGift(virtualItem, viewModel.Packages.StartPlus, item)
          "
          :is-start-text-only="
            (((showSubmodusInput(virtualItem, viewModel.Packages.Start, item) &&
              getSubmodusValues(virtualItem, viewModel.Packages.Start, item)
                .length == 1) ||
              getSubmodusReadOnly(
                virtualItem,
                viewModel.Packages.Start,
                item
              )) &&
              !isSubmodusGift(virtualItem, viewModel.Packages.Start, item)) ||
              isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
          "
          :is-gift="
            isSubmodusGift(virtualItem, viewModel.Packages.Extra, item) ||
              isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item) ||
              isSubmodusGift(virtualItem, viewModel.Packages.StartPlus, item) ||
              isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
          "
        >
          <template #first>
            <div>
              <SubmodusTitle
                v-sanitize="virtualItem.Name" 
                :icon-size="16"
                :tooltip-content="virtualItem.InformationContent"
                :tooltip-title="virtualItem.Name"
              />
              <div class="submodus-modification-button-holder">
                <div
                  class="submodus-modification-button"
                  :class="
                    (showSubmodusInput(
                      virtualItem,
                      viewModel.Packages.Extra,
                      item
                    ) &&
                      !isSubmodusGift(
                        virtualItem,
                        viewModel.Packages.Extra,
                        item
                      )) ||
                      (showSubmodusInput(
                        virtualItem,
                        viewModel.Packages.Komfort,
                        item
                      ) &&
                        !isSubmodusGift(
                          virtualItem,
                          viewModel.Packages.Komfort,
                          item
                        )) ||
                      (showSubmodusInput(
                        virtualItem,
                        viewModel.Packages.StartPlus,
                        item
                      ) &&
                        !isSubmodusGift(
                          virtualItem,
                          viewModel.Packages.StartPlus,
                          item
                        )) ||
                      (showSubmodusInput(
                        virtualItem,
                        viewModel.Packages.Start,
                        item
                      ) &&
                        !isSubmodusGift(
                          virtualItem,
                          viewModel.Packages.Start,
                          item
                        ))
                      ? 'active'
                      : ''
                  "
                  @click="removeSubmodus(virtualItem, item)"
                >
                  Törlés
                </div>
                <div
                  class="submodus-modification-button"
                  :class="
                    (!showSubmodusInput(
                      virtualItem,
                      viewModel.Packages.Extra,
                      item
                    ) &&
                      !isSubmodusGift(
                        virtualItem,
                        viewModel.Packages.Extra,
                        item
                      )) ||
                      (!showSubmodusInput(
                        virtualItem,
                        viewModel.Packages.Komfort,
                        item
                      ) &&
                        !isSubmodusGift(
                          virtualItem,
                          viewModel.Packages.Komfort,
                          item
                        )) ||
                      (!showSubmodusInput(
                        virtualItem,
                        viewModel.Packages.StartPlus,
                        item
                      ) &&
                        !isSubmodusGift(
                          virtualItem,
                          viewModel.Packages.StartPlus,
                          item
                        )) ||
                      (!showSubmodusInput(
                        virtualItem,
                        viewModel.Packages.Start,
                        item
                      ) &&
                        !isSubmodusGift(
                          virtualItem,
                          viewModel.Packages.Start,
                          item
                        ))
                      ? 'active'
                      : ''
                  "
                  @click="addSubmodus(virtualItem, item)"
                >
                  HOZZÁADÁS
                </div>
              </div>
            </div>
          </template>
          <template
            v-if="
              showSubmodusInput(virtualItem, viewModel.Packages.Extra, item)
            "
            #second
          >
            <SelectInput
              v-if="
                showSubmodusInput(
                  virtualItem,
                  viewModel.Packages.Extra,
                  item
                ) &&
                  getSubmodusValues(virtualItem, viewModel.Packages.Extra, item)
                    .length > 1 &&
                  !isSubmodusGift(virtualItem, viewModel.Packages.Extra, item)
              "
              :id="'extra_' + virtualItem.Id"
              :key="
                getSubmodusValue(virtualItem, viewModel.Packages.Extra, item)
                  .Value
              "
              v-model="
                getSubmodusValue(virtualItem, viewModel.Packages.Extra, item)
                  .Value
              "
              :is-disabled="isGTDisabled"
              :texts="texts"
              :options="
                getSubmodusValues(virtualItem, viewModel.Packages.Extra, item)
              "
              :init-option-value="
                getSubmodusValue(virtualItem, viewModel.Packages.Extra, item)
                  .Value.value
              "
              :format-number="true"
              :suffix="getSubmodusPostfix(virtualItem)"
              @update:model-value="
                setSubmodusValue(
                  virtualItem,
                  getSubmodusValue(virtualItem, viewModel.Packages.Extra, item)
                    .Value,
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.Extra,
                    item
                  ),
                  item
                )
              "
            />
            <div
              v-if="
                showSubmodusInput(
                  virtualItem,
                  viewModel.Packages.Extra,
                  item
                ) &&
                  getSubmodusValues(virtualItem, viewModel.Packages.Extra, item)
                    .length == 1 &&
                  !isSubmodusGift(virtualItem, viewModel.Packages.Extra, item)
              "
            >
              {{
                getFormattedNumber(
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.Extra,
                    item
                  )[0].label
                ) +
                  ' ' +
                  getSubmodusPostfix(virtualItem)
              }}
            </div>
            <div
              v-if="isSubmodusGift(virtualItem, viewModel.Packages.Extra, item)"
            >
              {{
                getSubmodusGiftValue(getSubmodusValues(virtualItem, viewModel.Packages.Extra, item)) +
                  ' ' +
                  getSubmodusPostfix(virtualItem)
              }}
            </div>
          </template>
          <template
            v-if="
              !showSubmodusInput(virtualItem, viewModel.Packages.Extra, item) &&
                !isSubmodusGift(virtualItem, viewModel.Packages.Extra, item)
            "
            #secondIcon
          >
            <PhIconManager
              class="submodus-cross"
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-x"
            />
          </template>
          <template
            v-if="
              showSubmodusInput(virtualItem, viewModel.Packages.Komfort, item)
            "
            #third
          >
            <SelectInput
              v-if="
                showSubmodusInput(
                  virtualItem,
                  viewModel.Packages.Komfort,
                  item
                ) &&
                  getSubmodusValues(virtualItem, viewModel.Packages.Komfort, item)
                    .length > 1 &&
                  !isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item)
              "
              :id="'komfort_' + virtualItem.Id"
              :key="
                getSubmodusValue(virtualItem, viewModel.Packages.Komfort, item)
                  .Value
              "
              v-model="
                getSubmodusValue(virtualItem, viewModel.Packages.Komfort, item)
                  .Value
              "
              :is-disabled="isGTDisabled"
              :texts="texts"
              :options="
                getSubmodusValues(virtualItem, viewModel.Packages.Komfort, item)
              "
              :init-option-value="
                getSubmodusValue(virtualItem, viewModel.Packages.Komfort, item)
                  .Value.value
              "
              :format-number="true"
              :suffix="getSubmodusPostfix(virtualItem)"
              @update:model-value="
                setSubmodusValue(
                  virtualItem,
                  getSubmodusValue(
                    virtualItem,
                    viewModel.Packages.Komfort,
                    item
                  ).Value,
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.Komfort,
                    item
                  ),
                  item
                )
              "
            />
            <div
              v-if="
                showSubmodusInput(
                  virtualItem,
                  viewModel.Packages.Komfort,
                  item
                ) &&
                  getSubmodusValues(virtualItem, viewModel.Packages.Komfort, item)
                    .length == 1 &&
                  !isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item)
              "
            >
              {{
                getFormattedNumber(
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.Komfort,
                    item
                  )[0].label
                ) +
                  ' ' +
                  getSubmodusPostfix(virtualItem)
              }}
            </div>
            <div
              v-if="
                isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item)
              "
            >
              {{
                getSubmodusGiftValue(getSubmodusValues(virtualItem, viewModel.Packages.Komfort, item)) +
                  ' ' +
                  getSubmodusPostfix(virtualItem)
              }}
            </div>
          </template>
          <template
            v-if="
              !showSubmodusInput(
                virtualItem,
                viewModel.Packages.Komfort,
                item
              ) &&
                !isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item)
            "
            #thirdIcon
          >
            <PhIconManager
              class="submodus-cross"
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-x"
            />
          </template>
          <template
            v-if="
              showSubmodusInput(virtualItem, viewModel.Packages.StartPlus, item)
            "
            #fourth
          >
            <SelectInput
              v-if="
                showSubmodusInput(
                  virtualItem,
                  viewModel.Packages.StartPlus,
                  item
                ) &&
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  ).length > 1 &&
                  !isSubmodusGift(virtualItem, viewModel.Packages.StartPlus, item)
              "
              :id="'startPlus_' + virtualItem.Id"
              :key="
                getSubmodusValue(
                  virtualItem,
                  viewModel.Packages.StartPlus,
                  item
                ).Value
              "
              v-model="
                getSubmodusValue(
                  virtualItem,
                  viewModel.Packages.StartPlus,
                  item
                ).Value
              "
              :is-disabled="isGTDisabled"
              :init-option-value="
                getSubmodusValue(
                  virtualItem,
                  viewModel.Packages.StartPlus,
                  item
                ).Value.value
              "
              :texts="texts"
              :options="
                getSubmodusValues(
                  virtualItem,
                  viewModel.Packages.StartPlus,
                  item
                )
              "
              :format-number="true"
              :suffix="getSubmodusPostfix(virtualItem)"
              @update:model-value="
                setSubmodusValue(
                  virtualItem,
                  getSubmodusValue(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  ).Value,
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  ),
                  item
                )
              "
            />
            <div
              v-if="
                showSubmodusInput(
                  virtualItem,
                  viewModel.Packages.StartPlus,
                  item
                ) &&
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  ).length == 1 &&
                  !isSubmodusGift(virtualItem, viewModel.Packages.StartPlus, item)
              "
            >
              {{
                getFormattedNumber(
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  )[0].label
                ) +
                  ' ' +
                  getSubmodusPostfix(virtualItem)
              }}
            </div>
            <div
              v-if="
                isSubmodusGift(virtualItem, viewModel.Packages.StartPlus, item)
              "
            >
              {{
                getSubmodusGiftValue(getSubmodusValues(virtualItem, viewModel.Packages.StartPlus, item)) + ' Ft'
              }}
            </div>
          </template>
          <template
            v-if="
              !showSubmodusInput(
                virtualItem,
                viewModel.Packages.StartPlus,
                item
              ) &&
                !isSubmodusGift(virtualItem, viewModel.Packages.StartPlus, item)
            "
            #fourthIcon
          >
            <PhIconManager
              class="submodus-cross"
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-x"
            />
          </template>
          <template
            v-if="
              showSubmodusInput(virtualItem, viewModel.Packages.Start, item) ||
                isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
            "
            #fifth
          >
            <SelectInput
              v-if="
                showSubmodusInput(
                  virtualItem,
                  viewModel.Packages.Start,
                  item
                ) &&
                  getSubmodusValues(virtualItem, viewModel.Packages.Start, item)
                    .length > 1 &&
                  !isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
              "
              :id="'start_' + virtualItem.Id"
              :key="
                getSubmodusValue(virtualItem, viewModel.Packages.Start, item)
                  .Value
              "
              v-model="
                getSubmodusValue(virtualItem, viewModel.Packages.Start, item)
                  .Value
              "
              :is-disabled="isMFODisabled"
              :init-option-value="
                getSubmodusValue(virtualItem, viewModel.Packages.Start, item)
                  .Value.value
              "
              :texts="texts"
              :options="
                getSubmodusValues(virtualItem, viewModel.Packages.Start, item)
              "
              :format-number="true"
              :suffix="getSubmodusPostfix(virtualItem)"
              @update:model-value="
                setSubmodusValue(
                  virtualItem,
                  getSubmodusValue(virtualItem, viewModel.Packages.Start, item)
                    .Value,
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.Start,
                    item
                  ),
                  item
                )
              "
            />
            <div
              v-if="
                showSubmodusInput(
                  virtualItem,
                  viewModel.Packages.Start,
                  item
                ) &&
                  getSubmodusValues(virtualItem, viewModel.Packages.Start, item)
                    .length == 1 &&
                  !isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
              "
            >
              {{
                getFormattedNumber(
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.Start,
                    item
                  )[0].label
                ) +
                  ' ' +
                  getSubmodusPostfix(virtualItem)
              }}
            </div>
            <div
              v-if="isSubmodusGift(virtualItem, viewModel.Packages.Start, item)"
            >
              {{
                getFormattedNumber(
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.Start,
                    item
                  )[0].label
                ) +
                  ' ' +
                  getSubmodusPostfix(virtualItem)
              }}
            </div>
          </template>
          <template
            v-if="
              !showSubmodusInput(virtualItem, viewModel.Packages.Start, item) &&
                !isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
            "
            #fifthIcon
          >
            <PhIconManager
              class="submodus-cross"
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-x"
            />
          </template>
        </MFODesktopPackageRowLayout>
      </q-expansion-item>
    </template>
  </div>
</template>

<!-- //ANCHOR - Script -->
<script setup>
import { ref, inject, computed, onMounted, watch } from 'vue'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'
import {
  customDefineRule,
  setSkipEmptyFields,
} from '@/utils/validations/customDefineRule'
const { GtHouseExclusion, MFOExclusion } = inject('exclusion')
const { callCalculate, callValidate } = inject('wizard')

const texts = inject('texts')
let viewModel = inject('viewModel')
const submodusPostfixes = ref(new Map())
const emit = defineEmits(['packageSelected'])
const { getFormattedNumber } = inject('util')
let { isMFODisabled } = inject('isMFODisabled')
let { isGTDisabled } = inject('isGTDisabled')

onMounted(() => {
  setSubmodusPostfixes()
  accordionDefaultValues()
})

const revalidate  = ref([false,false,false,false,false])

function revalidateinput(index){
  revalidate.value[index] = true
}

const visibleAdditionalCategories = computed(() => {
  if (viewModel.value.MNBMode == undefined) {
    return viewModel.value.Packages.Union.Additionals.filter(
      (additional) => !additional.IsHidden
    )
  }

  let result = []

  viewModel.value.Packages.Start.Additionals.forEach((element) => {
    if (element.Selected.Value) result.push(element)
    if (element.IsVirtualPackage) {
      element.Items.forEach((innerElement) => {
        if (innerElement.Selected.Value) result.push(innerElement)
      })
    }
  })

  return result
})

const accordion = ref([])

function isAccordionHasGiftOpen(index) {
  let visibleExtra = []
  let visibleStart = []
  if (viewModel.value.MNBMode == undefined) {
    for (
      let index = 0;
      index < viewModel.value.Packages.Extra.Additionals.length;
      index++
    ) {
      if (!viewModel.value.Packages.Union.Additionals[index].IsHidden) {
        visibleExtra.push(viewModel.value.Packages.Extra.Additionals[index])
      }
    }
    if (visibleExtra[index].IsVirtualPackage) {
      return visibleExtra[index].Items.some((element) => element.IsGift == true)
    }
  }
  for (
    let index = 0;
    index < viewModel.value.Packages.Start.Additionals.length;
    index++
  ) {
    if (viewModel.value.MNBMode == undefined) {
      if (!viewModel.value.Packages.Union.Additionals[index].IsHidden) {
        visibleStart.push(viewModel.value.Packages.Start.Additionals[index])
      }
    } else {
      if (!viewModel.value.Packages.Start.Additionals[index].IsHidden) {
        visibleStart.push(viewModel.value.Packages.Start.Additionals[index])
      }
    }
  }
  if (visibleStart[index].IsVirtualPackage) {
    return visibleStart[index].Items.some((element) => element.IsGift == true)
  }

  return false
}
function accordionDefaultValues() {
  let tomb = []
  for (
    let index = 0;
    index < visibleAdditionalCategories.value.length;
    index++
  ) {
    tomb.push(isAccordionHasGiftOpen(index))
  }
  accordion.value = tomb
}
function setTextValue(value, modifiedPackage) {
  const asd = viewModel.value.Packages.find(
    (packages) => packages.ProductCode != modifiedPackage.ProductCode
  )
}
function setSubmodusPostfixes() {
  submodusPostfixes.value.set(
    'PenzugyiVesztesegKockazatokDijAtvallalas',
    ' hónap'
  )
  submodusPostfixes.value.set('BalesetBiztositasCsomag', '')
  submodusPostfixes.value.set('HobbiKisallatBiztositasCsomag', '')
}

function steadingLabel(index) {
  return texts.vtxt_kalk159_label.replace('[]', index + 1)
}

function packageSelected(value) {
  emit('packageSelected', value)
}

function getSubmodus(item, householdPackage, isInVirtualPackage = null) {
  let result
  if (!isInVirtualPackage) {
    result = householdPackage.Additionals.find(
      (additional) => additional.Id === item.Id
    )
  } else {
    const virtualPackage = householdPackage.Additionals.find(
      (additional) => additional.Id === isInVirtualPackage.Id
    )
    result = virtualPackage.Items.find(
      (virtualPackageAdditional) => virtualPackageAdditional.Id === item.Id
    )
  }

  if (viewModel.value.MNBMode && result === undefined) {
    householdPackage.Additionals.forEach((element) => {
      if (result === undefined && element.IsVirtualPackage) {
        result = element.Items.find(
          (innerElement) => innerElement.Id === item.Id
        )
        if (result) return result
      }
    })
  }

  return result
}

function showSubmodusInput(item, householdPackage, isInVirtualPackage = null) {
  const subModus = getSubmodus(item, householdPackage, isInVirtualPackage)
  return subModus.Selected.Value
}
function getSubmodusValues(item, householdPackage, isInVirtualPackage = null) {
  const subModus = getSubmodus(item, householdPackage, isInVirtualPackage)
  return subModus.Options.Values
}
function getSubmodusValue(item, householdPackage, isInVirtualPackage = null) {
  const subModus = getSubmodus(item, householdPackage, isInVirtualPackage)
  return subModus.Options
}
function getSubmodusReadOnly(
  item,
  householdPackage,
  isInVirtualPackage = null
) {
  const subModus = getSubmodus(item, householdPackage, isInVirtualPackage)
  return subModus.ReadOnly
}

function isSubmodusGift(item, householdPackage, isInVirtualPackage = null) {
  const subModus = getSubmodus(item, householdPackage, isInVirtualPackage)
  return subModus.IsGift
}

function getSubmodusPostfix(item) {
  return submodusPostfixes.value.has(item.Id)
    ? submodusPostfixes.value.get(item.Id)
    : ' Ft'
}

async function setSubmodusValue(
  item,
  selectedValue,
  selectedValues,
  isInVirtualPackage = null
) {
  let valueToSet = selectedValues.find((value) => value.value === selectedValue)

  let valuetoSetObject = {
    value: valueToSet.value,
    label: valueToSet.label,
    IsDisabled: false,
  }

  if (viewModel.value.MNBMode == undefined) {
    const extra = getSubmodus(
      item,
      viewModel.value.Packages.Extra,
      isInVirtualPackage
    )
    const komfort = getSubmodus(
      item,
      viewModel.value.Packages.Komfort,
      isInVirtualPackage
    )
    const startPlus = getSubmodus(
      item,
      viewModel.value.Packages.StartPlus,
      isInVirtualPackage
    )
    extra.Options.Value = valuetoSetObject
    komfort.Options.Value = valuetoSetObject
    startPlus.Options.Value = valuetoSetObject
  }

  const start = getSubmodus(
    item,
    viewModel.value.Packages.Start,
    isInVirtualPackage
  )
  start.Options.Value = valuetoSetObject
  await callCalculate(true,2000)
}
function getSelectedSubmodusForPackage(submodusList, packageId) {
  if (submodusList.length === 1) {
    return submodusList[0]
  } else {
    return submodusList.find((value) => value.value === packageId)
  }
}
async function addSubmodus(item, isInVirtualPackage = null) {
  let extra = null
  let komfort = null
  let startPlus = null
  if (viewModel.value.MNBMode == undefined) {
    extra = getSubmodus(
      item,
      viewModel.value.Packages.Extra,
      isInVirtualPackage
    )
    komfort = getSubmodus(
      item,
      viewModel.value.Packages.Komfort,
      isInVirtualPackage
    )
    startPlus = getSubmodus(
      item,
      viewModel.value.Packages.StartPlus,
      isInVirtualPackage
    )

    if (
      extra.Options.Value.Id === null ||
      extra.Options.Value.Id === undefined ||
      extra.Options.Value.Id === -1
    ) {
      const setExtra = getSelectedSubmodusForPackage(extra.Options.Values, 3)
      let valuetoSetObject = {
        Id: setExtra.value,
        Text: setExtra.label,
        IsDisabled: false,
      }
      extra.Options.Value = valuetoSetObject
    }
    if (
      komfort.Options.Value.Id === null ||
      komfort.Options.Value.Id === undefined ||
      komfort.Options.Value.Id === -1
    ) {
      const setKomfort = getSelectedSubmodusForPackage(
        komfort.Options.Values,
        2
      )
      let valuetoSetObject = {
        Id: setKomfort.value,
        Text: setKomfort.label,
        IsDisabled: false,
      }
      komfort.Options.Value = valuetoSetObject
    }
    if (
      startPlus.Options.Value.Id === null ||
      startPlus.Options.Value.Id === undefined ||
      startPlus.Options.Value.Id === -1
    ) {
      const setStartP = getSelectedSubmodusForPackage(
        startPlus.Options.Values,
        1
      )
      let valuetoSetObject = {
        Id: setStartP.value,
        Text: setStartP.label,
        IsDisabled: false,
      }
      startPlus.Options.Value = valuetoSetObject
    }

    if (!extra.IsGift) extra.Selected.Value = true
    if (!komfort.IsGift) komfort.Selected.Value = true
    if (!startPlus.IsGift) startPlus.Selected.Value = true
  }

  const start = getSubmodus(
    item,
    viewModel.value.Packages.Start,
    isInVirtualPackage
  )

  if (
    start.Options.Value.Id === null ||
    start.Options.Value.Id === undefined ||
    start.Options.Value.Id === -1
  ) {
    const setStart = getSelectedSubmodusForPackage(start.Options.Values, 0)
    let valuetoSetObject = {
      Id: setStart.value,
      Text: setStart.label,
      IsDisabled: false,
    }
    start.Options.Value = valuetoSetObject
  }

  if (!start.IsGift) start.Selected.Value = true

  if (isInVirtualPackage) {
    if (viewModel.value.MNBMode == undefined) {
      const virtualExtra = getSubmodus(
        isInVirtualPackage,
        viewModel.value.Packages.Extra
      )
      const virtualKomfort = getSubmodus(
        isInVirtualPackage,
        viewModel.value.Packages.Komfort
      )
      const virtualStartPlus = getSubmodus(
        isInVirtualPackage,
        viewModel.value.Packages.StartPlus
      )

      if (
        virtualExtra.Options.Value.Id === null ||
        virtualExtra.Options.Value.Id === -1
      ) {
        const setVirtualExtra = getSelectedSubmodusForPackage(
          virtualExtra.Options.Values,
          3
        )
        let valuetoSetObject = {
          Id: setVirtualExtra.value,
          Text: setVirtualExtra.label,
          IsDisabled: false,
        }
        virtualExtra.Options.Value = valuetoSetObject
      }
      if (
        virtualKomfort.Options.Value.Id === null ||
        virtualKomfort.Options.Value.Id === -1
      ) {
        const setVirtualKomfort = getSelectedSubmodusForPackage(
          virtualKomfort.Options.Values,
          2
        )
        let valuetoSetObject = {
          Id: setVirtualKomfort.value,
          Text: setVirtualKomfort.label,
          IsDisabled: false,
        }
        virtualKomfort.Options.Value = valuetoSetObject
      }
      if (
        virtualStartPlus.Options.Value.Id === null ||
        virtualStartPlus.Options.Value.Id === -1
      ) {
        const setVirtualStartPlus = getSelectedSubmodusForPackage(
          virtualStartPlus.Options.Values,
          1
        )
        let valuetoSetObject = {
          Id: setVirtualStartPlus.value,
          Text: setVirtualStartPlus.label,
          IsDisabled: false,
        }
        virtualStartPlus.Options.Value = valuetoSetObject
      }

      if (!extra.IsGift) virtualExtra.Selected.Value = true
      if (!komfort.IsGift) virtualKomfort.Selected.Value = true
      if (!startPlus.IsGift) virtualStartPlus.Selected.Value = true
    }

    const virtualStart = getSubmodus(
      isInVirtualPackage,
      viewModel.value.Packages.Start
    )

    if (
      virtualStart.Options.Value.Id === null ||
      virtualStart.Options.Value.Id === -1
    ) {
      const setVirtualStart = getSelectedSubmodusForPackage(
        virtualStart.Options.Values,
        0
      )
      let valuetoSetObject = {
        Id: setVirtualStart.value,
        Text: setVirtualStart.label,
        IsDisabled: false,
      }
      virtualStart.Options.Value = valuetoSetObject
    }

    if (!start.IsGift) virtualStart.Selected.Value = true
  }
  await callCalculate(true,2000)
}
customDefineRule('VAL_380', (value) => {
  let sum = 0
  viewModel.value.SteadingList.forEach((element) => {
    sum += Number(element.SteadingValue.Value)
  })
  if (sum > 30000000) {
    return ValidationMessages.VAL_380
  } else {
    return true
  }
})
customDefineRule('VAL_446', (value) => {
  let index = 0
  for (index; index < viewModel.value.SteadingList.length - 1; index++) {
    if (
      Number(viewModel.value.SteadingList[index].SteadingValue.Value) ==
      Number(value)
    ) {
      break
    }
  }
  let min =
    viewModel.value.SteadingList[index].SteadingRecommendedValue.Value * 0.7
  if (value < min) {
    return ValidationMessages.VAL_446.replace(
      '{0}',
      min.toLocaleString().replaceAll(',', ' ')
    )
  } else {
    return true
  }
})

async function removeSubmodus(item, isInVirtualPackage = null) {
  const extra = getSubmodus(
    item,
    viewModel.value.Packages.Extra,
    isInVirtualPackage
  )
  const komfort = getSubmodus(
    item,
    viewModel.value.Packages.Komfort,
    isInVirtualPackage
  )
  const startPlus = getSubmodus(
    item,
    viewModel.value.Packages.StartPlus,
    isInVirtualPackage
  )
  if (viewModel.value.MNBMode == undefined) {
    if (!extra.IsGift) {
      extra.Selected.Value = false
    }
    if (!komfort.IsGift) {
      komfort.Selected.Value = false
    }
    if (!startPlus.IsGift) {
      startPlus.Selected.Value = false
    }
  }
  const start = getSubmodus(
    item,
    viewModel.value.Packages.Start,
    isInVirtualPackage
  )
  if (!start.IsGift) {
    start.Selected.Value = false
  }

  if (isInVirtualPackage) {
    if (viewModel.value.MNBMode == undefined) {
      const virtualExtra = getSubmodus(
        isInVirtualPackage,
        viewModel.value.Packages.Extra
      )
      const virtualKomfort = getSubmodus(
        isInVirtualPackage,
        viewModel.value.Packages.Komfort
      )
      const virtualStartPlus = getSubmodus(
        isInVirtualPackage,
        viewModel.value.Packages.StartPlus
      )

      const extraResult = virtualExtra.Items.every((v) => !v.Selected.Value)
      const komfortResult = virtualKomfort.Items.every((v) => !v.Selected.Value)
      const startPlusResult = virtualStartPlus.Items.every(
        (v) => !v.Selected.Value
      )

      virtualExtra.Selected.Value = !extraResult
      virtualKomfort.Selected.Value = !komfortResult
      virtualStartPlus.Selected.Value = !startPlusResult
    }

    const virtualStart = getSubmodus(
      isInVirtualPackage,
      viewModel.value.Packages.Start
    )

    const startResult = virtualStart.Items.every((v) => !v.Selected.Value)
    virtualStart.Selected.Value = !startResult
  }
  if (!extra.IsGift || !komfort.IsGift || !startPlus.IsGift || !start.IsGift) {
    await callCalculate(true,2000)
  }
}
async function addVirtualPackage(item, householdPackage) {
  for (let i = 0; i < item.Items.length; i++) {
    const virtualPackage = item.Items[i]
    const subModusValues = getSubmodusValues(
      virtualPackage,
      householdPackage,
      item
    )

    if (subModusValues.length > 0) {
      if (viewModel.value.MNBMode == undefined) {
        const virtualSubModusValuesExtra = getSubmodusValues(
          virtualPackage,
          viewModel.value.Packages.Extra,
          item
        )
        const virtualSubModusValuesKomfort = getSubmodusValues(
          virtualPackage,
          viewModel.value.Packages.Komfort,
          item
        )
        const virtualSubModusValuesStartPlus = getSubmodusValues(
          virtualPackage,
          viewModel.value.Packages.StartPlus,
          item
        )

        const virtualSubModusExtra = getSubmodus(
          virtualPackage,
          viewModel.value.Packages.Extra,
          item
        )
        const virtualSubModusKomfort = getSubmodus(
          virtualPackage,
          viewModel.value.Packages.Komfort,
          item
        )
        const virtualSubModusStartPlus = getSubmodus(
          virtualPackage,
          viewModel.value.Packages.StartPlus,
          item
        )
        virtualSubModusExtra.Selected.Value = true
        virtualSubModusKomfort.Selected.Value = true
        virtualSubModusStartPlus.Selected.Value = true

        const selectedValueExtra = getSelectedSubmodusForPackage(
          virtualSubModusValuesExtra,
          3
        )
        let selectedValueExtraObject = {
          value: selectedValueExtra.value,
          label: selectedValueExtra.label,
          IsDisabled: false,
        }
        virtualSubModusExtra.Options.Value = selectedValueExtraObject

        const selectedValueKomfort = getSelectedSubmodusForPackage(
          virtualSubModusValuesKomfort,
          2
        )

        let selectedValueKomfortObject = {
          value: selectedValueKomfort.value,
          label: selectedValueKomfort.label,
          IsDisabled: false,
        }
        virtualSubModusKomfort.Options.Value = selectedValueKomfortObject

        const selectedValueStartPlus = getSelectedSubmodusForPackage(
          virtualSubModusValuesStartPlus,
          1
        )

        let selectedValueStartPlusObject = {
          value: selectedValueStartPlus.value,
          label: selectedValueStartPlus.label,
          IsDisabled: false,
        }
        virtualSubModusStartPlus.Options.Value = selectedValueStartPlusObject
      }

      const virtualSubModusValuesStart = getSubmodusValues(
        virtualPackage,
        viewModel.value.Packages.Start,
        item
      )

      const virtualSubModusStart = getSubmodus(
        virtualPackage,
        viewModel.value.Packages.Start,
        item
      )
      virtualSubModusStart.Selected.Value = true

      const selectedValueStart = getSelectedSubmodusForPackage(
        virtualSubModusValuesStart,
        0
      )

      let selectedValueStartObject = {
        value: selectedValueStart.value,
        label: selectedValueStart.label,
        IsDisabled: false,
      }
      virtualSubModusStart.Options.Value = selectedValueStartObject
    }
  }

  if (viewModel.value.MNBMode == undefined) {
    const extra = getSubmodus(item, viewModel.value.Packages.Extra)
    const komfort = getSubmodus(item, viewModel.value.Packages.Komfort)
    const startPlus = getSubmodus(item, viewModel.value.Packages.StartPlus)
    extra.Selected.Value = true
    komfort.Selected.Value = true
    startPlus.Selected.Value = true
  }

  const start = getSubmodus(item, viewModel.value.Packages.Start)
  start.Selected.Value = true
  await callCalculate(true,2000)
}
async function removeVirtualPackage(item) {
  for (let i = 0; i < item.Items.length; i++) {
    const virtualPackage = item.Items[i]

    if (viewModel.value.MNBMode == undefined) {
      const virtualSubModusExtra = getSubmodus(
        virtualPackage,
        viewModel.value.Packages.Extra,
        item
      )
      const virtualSubModusKomfort = getSubmodus(
        virtualPackage,
        viewModel.value.Packages.Komfort,
        item
      )
      const virtualSubModusStartPlus = getSubmodus(
        virtualPackage,
        viewModel.value.Packages.StartPlus,
        item
      )
      if (!virtualSubModusExtra.IsGift) {
        virtualSubModusExtra.Selected.Value = false
      }
      if (!virtualSubModusKomfort.IsGift) {
        virtualSubModusKomfort.Selected.Value = false
      }
      if (!virtualSubModusStartPlus.IsGift) {
        virtualSubModusStartPlus.Selected.Value = false
      }
    }

    const virtualSubModusStart = getSubmodus(
      virtualPackage,
      viewModel.value.Packages.Start,
      item
    )
    if (!virtualSubModusStart.IsGift) {
      virtualSubModusStart.Selected.Value = false
    }
  }

  if (viewModel.value.MNBMode == undefined) {
    const extra = getSubmodus(item, viewModel.value.Packages.Extra)
    const komfort = getSubmodus(item, viewModel.value.Packages.Komfort)
    const startPlus = getSubmodus(item, viewModel.value.Packages.StartPlus)
    if (!extra.IsGift) {
      extra.Selected.Value = false
    }

    komfort.Selected.Value = false
    startPlus.Selected.Value = false
  }

  const start = getSubmodus(item, viewModel.value.Packages.Start)
  start.Selected.Value = false

  await callCalculate(true,2000)
}
function hasVirtualGift(item, householdPackage) {
  let submodus = getSubmodus(item, householdPackage)
  return submodus.Items.some((element) => element.IsGift == true)
}

function isVirtualPackageAddActive(item) {
  let start = false
  let startPlus = false
  let komfort = false
  let extra = false
  viewModel.value.Packages.Extra.Additionals.forEach((element) => {
    if (item.Id == element.Id) {
      if (element.Items.every((item) => item.Selected.Value == true)) {
        extra = true
      }
    }
  })
  viewModel.value.Packages.Komfort.Additionals.forEach((element) => {
    if (item.Id == element.Id) {
      if (element.Items.every((item) => item.Selected.Value == true)) {
        komfort = true
      }
    }
  })
  viewModel.value.Packages.StartPlus.Additionals.forEach((element) => {
    if (item.Id == element.Id) {
      if (element.Items.every((item) => item.Selected.Value == true)) {
        startPlus = true
      }
    }
  })
  viewModel.value.Packages.Start.Additionals.forEach((element) => {
    if (item.Id == element.Id) {
      if (element.Items.every((item) => item.Selected.Value == true)) {
        start = true
      }
    }
  })

  return !(start && startPlus && komfort && extra)
}
function isVirtualPackageDeleteActive(item) {
  let active = false
  let startPlus = false
  let komfort = false
  let extra = false
  viewModel.value.Packages.Extra.Additionals.forEach((element) => {
    if (item.Id == element.Id) {
      let noneGifts = element.Items.filter((item) => item.IsGift == false)
      if (noneGifts.every((item) => item.Selected.Value == true)) {
        active = true
      }
    }
  })
  viewModel.value.Packages.Komfort.Additionals.forEach((element) => {
    if (item.Id == element.Id) {
      let noneGifts = element.Items.filter((item) => item.IsGift == false)

      if (noneGifts.some((item) => item.Selected.Value == true)) {
        active = true
      }
    }
  })
  viewModel.value.Packages.StartPlus.Additionals.forEach((element) => {
    if (item.Id == element.Id) {
      let noneGifts = element.Items.filter((item) => item.IsGift == false)

      if (noneGifts.some((item) => item.Selected.Value == true)) {
        active = true
      }
    }
  })
  viewModel.value.Packages.Start.Additionals.forEach((element) => {
    if (item.Id == element.Id) {
      let noneGifts = element.Items.filter((item) => item.IsGift == false)

      if (noneGifts.some((item) => item.Selected.Value == true)) {
        active = true
      }
    }
  })

  return active
}

function isVirtualPackageOnlyMFOGift(item) {
  let start = false

  viewModel.value.Packages.Start.Additionals.forEach((element) => {
    if (item.Id == element.Id) {
      if (element.Items.some((item) => item.IsGift.Value == true)) {
        start = true
      }
    }
  })

  return start
}
function isVirtualPackageOnlyGTGift(item) {
  let komfort = false
  let startPlus = false

  let extra = false
  viewModel.value.Packages.Extra.Additionals.forEach((element) => {
    if (item.Id == element.Id) {
      if (element.Items.some((item) => item.IsGift.Value == true)) {
        extra = true
      }
    }
  })

  viewModel.value.Packages.Komfort.Additionals.forEach((element) => {
    if (item.Id == element.Id) {
      if (element.Items.some((item) => item.IsGift.Value == true)) {
        komfort = true
      }
    }
  })

  viewModel.value.Packages.StartPlus.Additionals.forEach((element) => {
    if (item.Id == element.Id) {
      if (element.Items.every((item) => item.IsGift.Value == true)) {
        startPlus = true
      }
    }
  })

  return startPlus && komfort && extra
}
watch(
  () => [viewModel.value.Cart.Payment.PaymentMode.Value, viewModel.value.Cart.Payment.PaymentPeriod.Value, viewModel.value.ContentDiscount.Value],
  (value, oldValue) => {
    isMFODisabled.value = MFOExclusion()
    isGTDisabled.value = GtHouseExclusion()
  }
)

function getSubmodusGiftValue(values){
  let returnableValue = 1
  if(values.length == 1){
    return values[0].label
  }
  values.forEach(value => {
    if(value.value == 1 ){
      returnableValue = value.label
    }
});
  return getFormattedNumber(returnableValue)
}
</script>
<style lang="scss" scoped>
.insurance-package {
  .submodus-modification-button-holder {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 0.5rem;
    margin-left: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1rem;
    .submodus-modification-button {
      color: #b4b9cb;
      pointer-events: none;
      &.active {
        cursor: pointer;
        pointer-events: auto;
        color: #4e95fb;
      }
    }
  }
  .package-accordion {
    :deep(.q-item) {
      padding: 0;
    }
    :deep(.package-accordion-header) {
      .package-accordion-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .package-accordion-icon {
        display: none;
      }
    }
    :deep(.q-expansion-item__content) {
      .submodus-name-holder {
        background: #f7f7fb;
      }
    }
  }
  .submodus-icon-holder {
    .submodus-check {
      color: #10b981;
    }
    .submodus-cross {
      color: #ed4353;
    }
  }
  :deep(.disabled-package) {
    .package-name {
      color: #8a91ab;
    }
    .package-price-holder {
      background: #eef0f7;
      color: #8a91ab;
    }
    .package-radio-input-holder {
      background: #eef0f7;
      .q-radio__label {
        color: #8a91ab;
      }
      span.no-outline {
        display: none;
      }
    }
    .submodus-input-holder,
    .submodus-icon-holder {
      color: #8a91ab;
      background: #eef0f7;
      .submodus-check,
      .submodus-cross {
        color: #b4b9cb;
      }
    }
  }
  :deep(.highlighted) {
    .package-price-holder,
    .package-radio-input-holder,
    .submodus-input-holder,
    .submodus-icon-holder {
      background: #f7f7fb;
    }
  }

  :deep(.col-2) {
    width: 19.5rem;
  }
}
.first-submodus-row {
  border-radius: 0.25rem 0rem 0rem 0rem;
  :deep(.submodus-name-holder) {
    border-radius: 0.25rem 0rem 0rem 0rem;
  }
}
</style>
