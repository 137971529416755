<template>
  <div class="container-xxl mini-carousel-module">
    <Swiper
      :pagination="{
        clickable: true,
      }"
      :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }"
      :modules="modules"
      class="swiper-usp"
      :slides-per-view="1"
      :free-mode="false"
      :centered-slides="false"
    >
      <SwiperSlide
        v-for="(item, index) in items"
        :key="index"
      >
        <div class="swiper-usp-carousel-item">
          {{ item.text }}
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Autoplay } from 'swiper'

// Import Swiper styles
import 'swiper/scss'
import 'swiper/css/pagination'

const modules = [Pagination, Autoplay]

const props = defineProps({
  items: {
    type: Object,
    default: () => {
      return [
        {
          text: '1 napos utazást is biztosítunk',
        },
        {
          text: '2. slider ugyanabban a magassagban kezdodik, mint az elso. Nincs fokusz veszetes',
        },
        {
          text: '3. slider Mindig a legmagasabb szoveg hatarozza meg a tobbi slider magassagat.',
        },
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
.swiper-usp {
  max-width: 33.75rem;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: $B-50;
  border: 1px solid $B-200;
  border-radius: 0.75rem;
  margin-left: 0;

  .swiper-usp-carousel-item {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: $dark;
    margin-bottom: $normal;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  :deep(.swiper-pagination-bullet) {
    background: $D-500;
    opacity: 0.5;
  }
  :deep(.swiper-pagination-bullet-active) {
    opacity: 1;
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .mini-carousel-module {
    background: white;
    padding: 1rem;
    .swiper-usp {
      max-width: calc(100% - 5rem);
      margin: 1.5rem 2.5rem;
    }
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .mini-carousel-module {
    .swiper-usp {
      max-width: calc(100% - 2rem);
      margin: 0.5rem 1rem;
    }
  }
}
</style>
